<template>
    <CModal
      :title="title"
      :color="color"
      :show.sync="showModal"
    >   
      <p v-if="isDeleting" style="font-weight: bold;">¿Estás seguro que deseas eliminar el pago seleccionado?</p>
      <p v-if="isValidate && form.status != 'validated'" style="font-weight: bold;">¿Estás seguro que deseas CONFIRMAR la validación de pago?</p>
      <p v-if="isValidate && form.status == 'validated'" style="font-weight: bold;">¿Estás seguro que deseas QUITAR la validación de pago?</p>
        <CForm v-if="!isDeleting && !isValidate" id="travel_form" method="POST" enctype="multipart/form-data">
          <CRow>
            <CCol md="12">
              <div role="group" class="form-group">
                <label>Fecha:</label>
                <v-date-picker
                  id="date"
                  name="date"
                  class="date-picker"
                  v-model="form.date"
                  locale="es-MX" size="sm"
                />
              </div>
            </CCol>
          </CRow>
          <CRow>
            <CCol md="12">
              <label>Monto Pagado (MXN):</label>
              <CInput class="input_centered"
                id="amount"
                name="amount"
                placeholder=""
                type="number"
                inputmode="numeric"
                pattern="\d*"
                v-model="form.amount"
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol md="12">
              <label>Observaciones:</label>
              <CTextarea v-model="form.observations" style="width: 100%; border-radius: 0.25rem; border-color: rgb(255,255,255);"
                id="observations"
                name="observations"
                rows="3"
                text="Must be 8-20 words long."
              ></CTextarea>
            </CCol>
          </CRow>
          <CRow>
            <CCol md="12">
              <CInput
                  type="file"
                  name="voucher"
                  id="voucher"
                  label="Comprobante de Pago:"
                  :lazy="false"
                  :value.sync="form.voucher"
              />
            </CCol>
          </CRow>
        </CForm>
        <template #footer>
          <CButton @click="closeModal" color="secondary">Cancelar</CButton>
          <CButton v-if="!isDeleting && !isValidate" @click="store" :color="color">{{form.id > 0 ? 'Guardar' : 'Registrar Pago'}}</CButton>
          <CButton v-if="form.status != 'validated' && isValidate" @click="validate" color="success">Validar Pago</CButton>
          <CButton v-if="form.status == 'validated' && isValidate" @click="devalidate" color="warning">Quitar Validación</CButton>
          <CButton v-if="isDeleting" @click="destroy" :color="color">Eliminar Pago</CButton>
        </template>
    </CModal>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required, minLength, email, sameAs, helpers } from "vuelidate/lib/validators"
import { DatePicker } from 'v-calendar'

import moment from 'moment';
import moment_timezone from 'moment-timezone'

moment.tz.setDefault('America/Mexico_city')

let today = new moment();
today = new moment(today.format("YYYY-MM-DD HH:m:ss")); 
let today2 = new moment(today.format("YYYY-MM-DD HH:m:ss")).subtract(1, 'month');

export default {
  name: 'PaymentModal',
  components: {
    'v-date-picker': DatePicker
  },
  props: {

  },
  data () {
    return {
      showModal: false,
      title: "Nuevo Pago",
      color: "info",
      isEdit: false,
      isValidate: false,
      isDeleting: false,
      payment: false,
      form: this.getEmptyForm(),
      submitted: false,
      content: "<h1>Some initial content</h1>",
      fields: [
        {key: 'name', label: 'Nombre'},
        {key: 'actions', label: '', class: 'actions' }
      ],
      customToolbar: [
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" }
        ],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ["link", "image", "video"],
        ["clean"] // remove formatting button
      ]
    }
  },
  mounted: async function() {

  },
  methods: {
    storeModal (student, payment, exchange_rate) {
      this.showModal = true;
      this.color = "success";
      this.title = 'Registrar Pago';
      this.isEdit = false;
      this.isDeleting = false;
      this.isValidate = false;
      this.submitted = false;

      let date = moment();

      this.form = {
        travel_id: student.travel_id,
        enrollment_id: student.enrollment_id,
        student_id: student.student_id !== undefined ? student.student_id : student.id,
        customer_id: student.customer_id,
        payment_id: payment.id,
        amount: "",
        date: date.toDate(),
        observations: ''
      };

      this.payment = payment;
    },
    updateModal (student, payment, exchange_rate) {
      this.showModal = true;
      this.color = "warning";
      this.title = 'Editar Pago';
      this.isEdit = true;
      this.isDeleting = false;
      this.isValidate = false;
      this.submitted = false;

      let date = payment.enrollment_payment.date != null ? moment(payment.enrollment_payment.date, "YYYY-MM-DD") : moment();

      this.form = {
        id: payment.enrollment_payment.id,
        amount: payment.enrollment_payment.amount,
        date: date.toDate(),
        travel_id: student.travel_id,
        enrollment_id: student.enrollment_id,
        student_id: student.student_id !== undefined ? student.student_id : student.id,
        customer_id: student.customer_id,
        payment_id: payment.id,
        observations: payment.enrollment_payment.observations,
        status: payment.enrollment_payment.status
      };

      this.payment = payment;
    },
    validateModal (student, payment, exchange_rate) {
      this.showModal = true;
      this.color = "success";
      this.title = 'Validar Pago';
      this.isEdit = true;
      this.isDeleting = false;
      this.isValidate = true;
      this.submitted = false;

      let date = payment.enrollment_payment.date != null ? moment(payment.enrollment_payment.date, "YYYY-MM-DD") : moment();

      this.form = {
        id: payment.enrollment_payment.id,
        amount: payment.enrollment_payment.amount,
        date: date.toDate(),
        travel_id: student.travel_id,
        enrollment_id: student.enrollment_id,
        student_id: student.student_id,
        customer_id: student.customer_id,
        payment_id: payment.id,
        observations: payment.enrollment_payment.observations,
        status: payment.enrollment_payment.status
      };

      this.payment = payment;
    },
    devalidateModal (student, payment, exchange_rate) {
      this.showModal = true;
      this.color = "warning";
      this.title = 'Quitar validación de Pago';
      this.isEdit = true;
      this.isDeleting = false;
      this.isValidate = true;
      this.submitted = false;

      let date = payment.enrollment_payment.date != null ? moment(payment.enrollment_payment.date, "YYYY-MM-DD") : moment();

      this.form = {
        id: payment.enrollment_payment.id,
        amount: payment.enrollment_payment.amount,
        date: date.toDate(),
        travel_id: student.travel_id,
        enrollment_id: student.enrollment_id,
        student_id: student.student_id,
        customer_id: student.customer_id,
        payment_id: payment.id,
        observations: payment.enrollment_payment.observations,
        status: payment.enrollment_payment.status
      };

      this.payment = payment;
    },
    validate (student, payment, exchange_rate) {
      this.$emit("validate", this.payment, this.form );

      this.closeModal();
    },
    devalidate (student, payment, exchange_rate) {
      this.$emit("devalidate", this.payment, this.form );

      this.closeModal();
    },
    deleteModal (student, payment, exchange_rate) {
      this.showModal = true;
      this.color = "danger";
      this.title = 'Eliminar Pago';
      this.isEdit = true;
      this.isDeleting = true;
      this.isValidate = false;
      this.submitted = false;

      let date = payment.enrollment_payment.date != null ? moment(payment.enrollment_payment.date, "YYYY-MM-DD") : moment();

      this.form = {
        id: payment.enrollment_payment.id,
        amount: payment.enrollment_payment.amount,
        date: date.toDate(),
        travel_id: student.travel_id,
        enrollment_id: student.enrollment_id,
        student_id: student.student_id,
        customer_id: student.customer_id,
        payment_id: payment.id,
        observations: payment.enrollment_payment.observations
      };

      this.payment = payment;
    },
    store () {
      this.$v.$touch();

      if (this.isValid) {
        this.submitted = true;

        const form = document.getElementById("travel_form");
        const formData = new FormData(form);

        formData.append("travel_id", this.form.travel_id);
        formData.append("enrollment_id", this.form.enrollment_id);
        formData.append("student_id", this.form.student_id);
        formData.append("customer_id", this.form.customer_id);
        formData.append("payment_id", this.form.payment_id);

        let date = new moment(this.form.date);
        date = date.format("YYYY-MM-DD");

        formData.append("date", date);

        if(this.form.id > 0){
          formData.append("id", this.form.id);
          this.$emit("update", this.payment, formData );
        }
        else{
          this.$emit("store", this.payment, formData );
        }

        this.closeModal();
      }
    },
    destroy () {
      this.$emit("destroy", this.payment, this.form );

      this.closeModal();
    },
    closeModal () {
      this.showModal = false;

      this.form = this.getEmptyForm()
      this.submitted = false
      this.$v.$reset()
    },
    getEmptyForm () {
      let date = moment();

      let form = {
          travel_id: 0,
          enrollment_id: 0,
          student_id: 0,
          customer_id: 0,
          payment_id: 0,
          amount: 0,
          date: date.toDate(),
          voucher: '',
          observations: ''
      };

      return form;
    },
    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      
      return !(field.$invalid || field.$model === '')
    },
  },
  computed: {
    formString () { return JSON.stringify(this.form, null, 4) },
    isValid () { return !this.$v.form.$invalid },
    isDirty () { return this.$v.form.$anyDirty },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      date: {
        required
      },
      amount: {
        required
      }
    }
  },
}
</script>